import refreshToken from '@/plugins/refresh-token'
import { api } from '../mainNode'

// Node api all functions
const shopGet = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'shop',
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.log('get shop err : ', err)

      return {}
    })

  return response
}

const uploadImage = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'shop/upload',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('upload image err : ', err)

      return {}
    })

  return response
}
const shopUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'shop',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update shop data err : ', err)

      return {}
    })

  return response
}
export default { shopGet, uploadImage, shopUpdate }
