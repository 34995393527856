<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import {
  sumdatetime, sumdateUpper,
} from '@/plugins/filters'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'

export default {
  setup() {
    const moment = require('moment')
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(13)
    const fontBody = ref(12)
    const shop_print_type = ref(0)
    const shopData = ref({})
    const frame = ref('')

    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res

      // getOpdAfter()
      printsOpdCard()
    })

    const printsOpdCard = () => {
      prints.printsOpdCard({
        opd_id: route.value.query.id.split(','),
      }).then(res => {
        dataTableList.value = res.map(item => ({
          ...item,
          opd_cc: item.opd_cc.split('\n').join(''),
          opd_hpi: item.opd_hpi.split('\n').join(''),
          opd_pmh: item.opd_pmh.split('\n').join(''),
          date_now: `${`( ${i18n.t('print_date')} : ${sumdatetime(
            moment().format(''),
            i18n.locale,
          )} )`}`,
          date_now2: `${i18n.t('date')} ${sumdateUpper(item.opd_date, i18n.locale)}`,
          customer_age: `${i18n.t('age')} : ${item.customer_age}`,
          check_list: item.check_list || [],
          course_list: item.course_list || [],
          drug_list: item.drug_list || [],
          check_before: item.check_before || [],
          check_after: item.check_after || [],
        }))
        savePDF(dataTableList.value)
      })
    }

    const savePDF = data => {
      const doc = new jsPDF('l', 'in', 'a5')
      doc.setProperties({
        title: `OPD CARD IMAGE - HN ${data[0].customer_id}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      doc.setTextColor('#212121')

      for (let a = 0; a < data.length; a++) {
        const space = 0.17
        const starterL = 0.1
        const starterR = 8.2
        const starterT = 0.2
        let starterAddressL = 0.2
        let starterAddressR = 0

        const arrLeft = [
          { name: i18n.t('name'), value: `${data[a].customer_fullname} HN: ${data[a].customer_id.replaceAll('\r', '')} VN: ${data[a].opd_code}` },
          { name: i18n.t('gender'), value: `${data[a].customer_gender} ${i18n.t('age')} : ${data[a].customer_age} ${i18n.t('blood_type')} : ${data[a].customer_blood}` },
          { name: i18n.t('telephone_number'), value: `${data[a].customer_tel} ${i18n.t('congenital_disease')} : ${data[a].customer_disease} ${i18n.t('be_allergic')} : ${data[a].customer_allergic}` },
          { name: i18n.t('address'), value: `${data[a].customer_address}` },

        ]
        const arrRight = [
          { name: '', value: `${data[a].date_now}` },
          { name: '', value: `${data[a].date_now2}` },
          { name: i18n.t('license_number'), value: `${data[a].shop_license}` },
          { name: i18n.t('nursing_address'), value: `${data[a].shop_address}` },
          { name: i18n.t('telephone_number'), value: `${data[a].shop_tel}` },
        ]

        doc.setFont('TH-Niramit', 'bold').setFontSize(fontTitle.value)
        doc.text(`${i18n.t('patient_treatment_certificate')} ${data[a].shop_name} ${data[a].shop_nature}`, starterL, starterT)
        doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value)
        arrLeft.forEach(item => {
          starterAddressL += space
          doc.text(`${item.name} ${item.value}`, starterL, starterAddressL)
        })
        arrRight.forEach(item => {
          starterAddressR += space
          doc.text(`${item.name} ${item.name == '' ? '' : ''} ${item.value}`, starterR, starterAddressR, null, null, 'right')
        })
        starterAddressL += 0.05
        starterAddressR += 0.05
        doc.setDrawColor('#212121')
        doc.setLineWidth(0.0001)
        doc.setFont('TH-Niramit', 'normal')
        doc.line(starterL, starterAddressL, starterR, starterAddressL)
        doc.line(starterR / 2, starterAddressL, starterR / 2, 8)
        const textCenterL = (starterR / 2) / 2
        const textCenterR = (starterR / 4) * 3
        starterAddressL += space + 0.1

        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${i18n.t('before')}`, textCenterL, starterAddressL, null, null, 'center')
        doc.text(`${i18n.t('after')}`, textCenterR, starterAddressL, null, null, 'center')

        // const ImageToLoad = new Image()

        // ImageToLoad.src = require('@/assets/images/3d-characters/dashboard/Y8.png')
        // const ImageToLoad1 = new Image()
        // const ImageToLoad = new Image()
        if (data[a].check_before.length) {
          // ImageToLoad.src = data[a].check_before[0].opdupload_image
          doc.addImage(data[a].check_before[0].opdupload_image, 'JPEG', 0.5, 1.5, 3.1, 3.1)
        }

        if (data[a].check_after.length) {
          // ImageToLoad1.src = data[a].check_after[0].opdupload_image
          doc.addImage(data[a].check_after[0].opdupload_image, 'JPEG', starterR - 3.55, 1.5, 3.1, 3.1)
        }

        doc.line(starterL, 5, starterR, 5)
        doc.text(`${i18n.t('note')} :`, starterL, 5.2, null, null, 'left')
        doc.setFont('TH-Niramit', 'normal')
        const comment = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[a].opd_comment
          .replaceAll('<p>', '')
          .replaceAll('</p>', '\n')
          .replaceAll('<li>', '- ')
          .replaceAll('</li>', '\n')
          .replaceAll('<ul>', '')
          .replaceAll('</ul>', '')
          .replaceAll('<ol>', '')
          .replaceAll('</ol>', ''),
        4.15)
        doc.text(starterL + 0.65, 5.2, comment)
        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${i18n.t('doctor_inspector')} :`, starterR / 2 + 0.1, 5.2, null, null, 'left')
        doc.setFont('TH-Niramit', 'normal')
        doc.text(`${data[a].user_fullname}`, starterR / 2 + 1.3, 5.2, null, null, 'left')

        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)

      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }

    return {
      frame,
    }
  },
}
</script>
