import refreshToken from '@/plugins/refresh-token'
import { api } from './main'
import { api as api2 } from './mainNode'

const printsOrder = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/order',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print order err : ', err)

      return {}
    })

  return response
}
const printsInvoice = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/orderpay',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print order payment err : ', err)

      return {}
    })

  return response
}
const printsOpdCard = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/opdcard',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print opd  card err : ', err)

      return {}
    })

  return response
}
const printsOpdImageAfter = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'check/opdlastafter',
      param: id,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print opd  card err : ', err)

      return {}
    })

  return response
}

const printsCertDoctor = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/cert',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print opd  card err : ', err)

      return {}
    })

  return response
}
const printsCertDoctorOpd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/certopd',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get print opd  card err : ', err)

      return {}
    })

  return response
}
const printsSendBodySick = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/phrf',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get send bodysick err : ', err)

      return {}
    })

  return response
}
const printsCertSick = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/sick',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get cert sick err : ', err)

      return {}
    })

  return response
}
const printsCertSickOpd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/sickopd',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get cert sick err : ', err)

      return {}
    })

  return response
}

const printsDrugLabel = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/drugstickers',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get drug label err : ', err)

      return {}
    })

  return response
}

const printsLabLabel = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/checkstickers',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get lab label err : ', err)

      return {}
    })

  return response
}
const printsAppointment = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/appoint',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get appointment prints err : ', err)

      return {}
    })

  return response
}

const printsSendCheckLab = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/labs',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get send check lab prints err : ', err)

      return {}
    })

  return response
}
const printsResultLab = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/labresults',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get result lab prints err : ', err)

      return {}
    })

  return response
}
const printsDrugTransferOut = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/transferout',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get Drug Trasfer out err : ', err)

      return {}
    })

  return response
}
const printsReduct = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/reduce',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get Reduct err : ', err)

      return {}
    })

  return response
}
const printsService = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'prints/serving',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get Service err : ', err)

      return {}
    })

  return response
}

export default {
  printsOrder,
  printsInvoice,
  printsOpdCard,
  printsCertDoctor,
  printsOpdImageAfter,
  printsSendBodySick,
  printsCertSick,
  printsDrugLabel,
  printsLabLabel,
  printsAppointment,
  printsSendCheckLab,
  printsResultLab,
  printsCertSickOpd,
  printsDrugTransferOut,
  printsCertDoctorOpd,
  printsReduct,
  printsService,
}
